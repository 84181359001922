<template>
  <div >
    <SEARCH :searchChange='onCallbackSearch' :list="searchArr"></SEARCH>
    <TABS :tabChange='onCallbackTab' :list="tabsArr"></TABS>
    <TABLE :selectChange='onCallbackSelect' :buttonChange='onCallbackButton' :list="tableArr" :data="dataArr"></TABLE>
    <PAGE :pageChange='onCallbackPage' :limitChange='onCallbackLimit' :count="count"></PAGE>
  </div>
</template>

<script>
import SEARCH from '@/components/search' // 顶部搜索
import TABS from '@/components/tabs' // 横向 button
import TABLE from '@/components/table' // 列表
import PAGE from '@/components/page' // 分页
import { getFace, delFace } from '@/api/face'
export default {
  name: 'FaceList',
  components: {
    SEARCH,
    TABS,
    TABLE,
    PAGE,
  },
  data() {
    return {
      searchArr: [
        {
          name: '姓名',
          type: 'input',
          options: [],
          key: 'name',
        },
        {
          name: '手机号',
          type: 'input',
          options: [],
          key: 'phone',
        },
        {
          name: '身份证',
          type: 'input',
          options: [],
          key: 'id',
        },
      ], // 搜索列表
      tabsArr: [
        {
          type: 'primary',
          name: '添加人员',
          func: '',
          isLoading: false,
        },
        {
          type: 'success',
          name: '批量下发',
          func: '',
          isLoading: false,

        },
        {
          type: 'success',
          name: '批量未下发',
          func: '',
          isLoading: false,

        },
        {
          type: 'danger',
          name: '批量删除',
          func: 'removeProject',
          isLoading: false,
        },
      ], // 标签列表
      tableArr: [
        {
          prop: 'nickname',
          label: '姓名',
          width: '120',
        },
        {
          prop: 'sex',
          label: '性别',
          width: '120',
        },
        {
          prop: 'unit_id.unit',
          label: '单位',
          width: '120',
        },
        {
          prop: 'unit_id.group',
          label: '分组',
          width: '120',
        },
        {
          prop: 'mobile',
          label: '手机号',
          width: '120',
        },
        {
          prop: 'idcard',
          label: '身份证',
          width: '120',
        },
        {
          prop: 'binddev',
          label: '正面照',
          width: '120',
          type: 'img',
        },
        {
          prop: 'is_issue',
          label: '是否下发至设备',
          width: '120',
        },
        {
          prop: '',
          label: '操作',
          width: '',
          type: 'but',
        },
      ], // 表头列表
      dataArr: [], // 数据列表
      butnArr: [
        {
          type: '',
          name: '编辑',
          func: '',
        },
        {
          type: 'danger',
          name: '删除',
          func: 'removeFace',
        },
      ], // 数据-操作列表
      count: 0, // 总页数
      formData: {
        page: 1,
        limit: 10,
        mobile: '',
        nickname: '',
        unit_id: '',
      },
      ids: '', // 要删除的id
      tabsIndex: 0, // tabs 按钮的索引
    }
  },
  created() {},
  mounted() {
    this._loadData()
  },
  methods: {
    _loadData: function () {
      this.formData.unit_id = this.$store.getters.USER_ID
      getFace(this.formData).then((res) => {
        const { result } = res

        this.count = result.count
        result.data.forEach((item) => {
          item.buttons = this.butnArr
          item.imgs = [{ url: item.positive_photo }]
        })
        this.dataArr = result.data
      })
    },

    // 打开新增工程弹窗
    openAddProject: function() {
      this.loading()
      this.$refs.addP.open()
    },

    // 新增工程
    addProject: function() {

    },

    // 删除人脸
    removeFace: function() {
      if (!this.ids) {
        this.$toast('请选择要删除的人脸库')
        this.loading()
        return false
      }
      delFace(this.ids).then(res => {
        this.$toast(res.message)
        this._loadData()
        this.loading()
      })
    },

    // 收到搜索回调
    onCallbackSearch: function(data) {
      const { phone, name, id } = data
      this.formData.unit_id = id
      this.formData.nickname = name
      this.formData.mobile = phone
      this._loadData()
    },
    // 收到 tabs 回调
    onCallbackTab: function({ item, index }) {
      this.loading(index)
      this[item.func]()
    },

    // 打开或关闭tabs 按钮loading
    loading: function(index) {
      index && (this.tabsIndex = index)
      this.tabsArr[this.tabsIndex].isLoading = !this.tabsArr[this.tabsIndex].isLoading
    },

    // 收到 选择 回调
    onCallbackSelect: function(ids) {
      this.ids = ids
    },
    // 收到 表格 内方法 回调
    onCallbackButton: function(item, func) {
      this.ids = item.id
      this[func]()
    },
    // 收到改变页码回调
    onCallbackPage: function(page) {
      this.formData.page = page
      this._loadData()
    },
    // 收到改变一页数量回调
    onCallbackLimit: function(limit) {
      this.formData.limit = limit
      this._loadData()
    },
  },
}
</script>

<style lang="stylus" scoped></style>
