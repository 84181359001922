import { PublicAxios } from '@/utils/publicAxios'
const $ = new PublicAxios()

// --------------------------------------------
// 人脸识别API接口
// --------------------------------------------

/**
 * 获取人脸库列表
 * @param {Object} data 详看接口文档
 * Boolean => isLoad 是否需要加载框 默认为false
 */
export const getFace = async data => {
  return await $.get({
    url: 'admin/face/faceList',
    data,
    isLoad: true,
  })
}

/**
 * 删除人脸库
 * @param {String} ids 人脸id
 */
export const delFace = async ids => {
  return await $.get({
    url: 'admin/face/faceDel',
    data: { ids },
  })
}

/**
 * 获取识别记录
 * @param {Object} data 详看接口文档
 */
export const getFaceLog = async data => {
  return await $.get({
    url: 'admin/face/faceLog',
    data,
  })
}

/**
 * 导出识别记录
 * @param {String} ids 人员id
 */
export const expFaceLog = async ids => {
  return await $.get({
    url: 'admin/face/userLogOut',
    data: { ids },
  })
}

/**
 * 删除人员
 * @param {String} ids 人员id
 */
export const delFaceLog = async ids => {
  return await $.get({
    url: 'admin/face/faceLogDel',
    data: { ids },
  })
}
